import React from "react"
import IllustrationBoarCut from "../images/illustrationBoarCut"
import style from "./InternetExplorerNotification.module.scss"

const InternetExplorerNotification = () => {
  return (
    <div className={style.container}>
      <div className={style.illustrationContainer}>
        <IllustrationBoarCut />
      </div>
      <div className={style.textContainer}>
        <h1 className={style.heading}>Uuups!</h1>
        <div className={style.lead}>
          Ihr Browser wird von dieser Webseite leider nicht unterstützt. Bitte
          benutzen Sie <a href="microsoft-edge:http://www.lafresa.ch">Edge</a>,{" "}
          <a href="https://www.mozilla.org/de/firefox/">Firefox</a> oder{" "}
          <a href="https://www.google.ch/chrome/">Google&nbsp;Chrome</a>.
        </div>
      </div>
    </div>
  )
}

export default InternetExplorerNotification
