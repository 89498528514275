import React from "react"

const IllustrationBoarCut = () => (
  <svg viewBox="0 0 545 476" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 475.054l15.131-10.256 42.515-10.138 18.447-48.014 36.14-10.948 37.138-10.948 22.037-6.256 8.387-61.977 11.583 1.583-8.236 60.827 40.729 23.442 48.476.678 41.311 7.669 5.209-6.802-20.534-12.889-14.328-.208-24.558-16.318-2.256-22.33 11.621-1.187 1.692 16.827 4.644 3.091 37.42-36.821 12.26-47.524-3.253 54.76-36.479 36.161 2.519 1.677 14.178.208 43.437 27.266 25.592-17.882-37.683-42.889 1.956-62.655-14.216-18.297-14.836 50.124-28.45-32.373-17.901-63.032-51.823-54.93-1.73 26.589-62.635-54.609 1.88 26.306-51.654-29.302L0 152.558v322.496zm0-334.432l95.724-19.288 36.46 20.691-2.351-32.807 66.171 57.699 1.936-29.792 72.207 76.524 13.952 49.107 61.488-17.939 8.386-61.977 11.584 1.583-8.237 60.827 40.729 23.442 48.476.678 41.312 7.67 5.208-6.803-20.533-12.889-14.329-.207-24.557-16.319-2.257-22.33 11.621-1.187 1.692 16.828 4.645 3.09 37.419-36.821 12.26-47.523-3.253 54.759-36.498 36.18 2.52 1.677 14.178.208 43.436 27.266 25.592-17.882-37.682-42.888 1.955-62.656-14.215-18.297-14.836 50.124-28.432-32.392-17.901-63.032-51.823-54.93-1.73 26.59-62.616-54.61 1.88 26.306L267.928 0 0 53.982v86.64zm415.95.404l-2.256 14.076 15.927 2.789-1.73-8.969-11.941-7.896zM241.791 274.703l-2.257 14.076 15.927 2.789-1.73-8.97-11.94-7.895z"
      fill="#fff"
    />
  </svg>
)

export default IllustrationBoarCut
