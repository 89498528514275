import React, { useEffect, useRef } from "react"
import TWEEN from "@tweenjs/tween.js"
import useInternetExplorerStatus from "../hooks/useInternetExplorerStatus"
import InternetExplorerNotification from "./InternetExplorerNotification"

const Wrapper = props => {
  const { children } = props
  const raf = useRef(null)

  useEffect(() => {
    const update = time => {
      raf.current = requestAnimationFrame(update)
      TWEEN.update(time)
    }

    raf.current = requestAnimationFrame(update)
    return () => cancelAnimationFrame(raf.current)
  }, [])

  const ie = useInternetExplorerStatus()

  return ie ? <InternetExplorerNotification /> : <div>{children}</div>
}

export default Wrapper
