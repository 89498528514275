exports.linkResolver = function linkResolver(doc) {
  if (doc.type === "page") {
    return "/" + doc.uid
  }

  if (doc.type === "imprint") {
    return "/" + doc.uid
  }

  if (doc.type === "page_not_found") {
    return "/404"
  }

  if (doc.type === "landing_page") {
    return "/"
  }

  return "/"
}
