import { useState, useEffect } from "react"

const useInternetExplorerStatus = () => {
  const [isIE, setIsIE] = useState(null)

  useEffect(() => {
    if (document?.documentMode) {
      console.info(
        `You are using Internet Explorer ${document.documentMode} which is not supported by this website.`
      )
      setIsIE(document.documentMode)
    }
  }, [])

  return isIE
}

export default useInternetExplorerStatus
