import "core-js/fn/promise"
import "core-js/fn/object/assign"
import "unfetch/polyfill"
import "intersection-observer"

import React from "react"
import Wrapper from "./src/components/Wrapper"
import { setConfig } from "react-hot-loader"
import "./src/styles/globals.scss"

require("smooth-scroll")("a[href*=\"#\"]")

const { registerLinkResolver } = require("gatsby-source-prismic-graphql")
const { linkResolver } = require("./src/utils/linkResolver")
registerLinkResolver(linkResolver)

setConfig({ showReactDomPatchNotification: false })

export const wrapRootElement = ({ element, props }) => <Wrapper>{element}</Wrapper>

